



































































































































































































































import { Chapter } from "@/models/Chapter";
import { Course } from "@/models/Course";
import { Batch, CoachingGroup, CoachingSession } from "@/models/models";
import { batchCollection } from "@/utils/db";
import { getUserNameByEmail } from "@/utils/utils";
import Vue from "vue";

import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Batch",
  components: {},
  data: () => ({
    editBatchDialog: false,
    addBatchDialog: false,
    addBatchData: { name: "", groups: [] } as Batch,
    selectedGroupIds: [],
    batchGroupFilter: [] as string[],
    headers: [
      { text: "Registered", value: "userId" },
      { text: "Name", value: "name" },
      { text: "Email", align: "start", value: "email" },
      { text: "", value: "groupCoachImage" },
      { text: "Coach", value: "groupCoach" },
      { text: "Start", value: "groupCoachingStart" },
      { text: "Next", value: "nextCoachingSession" },
      { text: "Next Session Progress", value: "partialProgress" },
      { text: "Total Progress", value: "totalProgress" },
      { text: "Group", align: "start", value: "group" },
      { text: "App Version", value: "appVersion" },
      { text: "Platform", value: "platform" },
    ],
  }),
  computed: {
    ...mapGetters([
      "groups",
      "allCourses",
      "rhoUsers",
      "userProgressCalculation",
      "profiles",
      "batches",
      "sortedBatches",
    ]),
    currentBatch(): Batch | undefined {
      return this.batches.find(
        (item: Batch) => item.id == this.$route.params.batchId
      );
    },
    currentBatchGroups(): CoachingGroup[] {
      let _currentBatchGroups: CoachingGroup[] = [];
      if (this.currentBatch) {
        this.currentBatch.groups.forEach((g_id: string) => {
          _currentBatchGroups.push(
            this.groups.find((i: CoachingGroup) => i.id === g_id)
          );
        });
      }
      return _currentBatchGroups;
    },
    currentBatchGroupsTree(): any {
      let children = this.currentBatchGroups.map((e) => {
        return { id: e.id, name: e.name || e.id };
      });
      return [{ id: 1, name: "Groups", children: children }];
    },
    allUsers(): {
      name: string;
      email: string;
      groupCoachingStart: string;
      nextCoachingSession?: CoachingSession;
      groupCoach: string;
      groupCoachImage: string;
      group: string;
      userId?: string;
      totalProgress?: string;
      partialProgress?: string;
      appVersion?: string;
      platform?: string;
    }[] {
      let users: {
        name: string;
        email: string;
        groupCoachingStart: string;
        nextCoachingSession?: CoachingSession;
        groupCoach: string;
        groupCoachImage: string;
        group: string;
        userId?: string;
        totalProgress?: string;
        partialProgress?: string;
        appVersion?: string;
        platform?: string;
      }[] = [];
      // TODO: remove optional types in users and use default values directly when setting them in the array the first time!
      // Group
      this.batchGroupFilter.forEach((g_id: string) => {
        let g = this.groups.find((e) => e.id === g_id);
        g?.users.forEach((email) => {
          let groupCoachingStart = "-";
          let nextCoachingSession;
          let groupCoach = "-";
          let groupCoachImage = "";

          if (g.coachings.length > 0) {
            groupCoachingStart = g.coachings[0]?.start || "-";
            nextCoachingSession = this.getNextCoachingSession(g);
            groupCoach = g.coachings[0]?.coach || "-";
            groupCoachImage = g.coachings[0]?.coachImage || "";
          }

          users.push({
            email: email,
            name: "-",
            groupCoachingStart: groupCoachingStart,
            nextCoachingSession: nextCoachingSession || null,
            groupCoach: groupCoach,
            groupCoachImage: groupCoachImage,
            group: g,
          });
        });
      });

      // Mail + UserId
      users = users.map((element) => {
        let match = this.rhoUsers.filter((u) => u.email === element.email);
        if (match.length > 0) {
          return {
            ...element,
            userId: match[0].userId,
            name: getUserNameByEmail(element.email) || "-",
          };
        } else {
          return { ...element, userId: undefined };
        }
      });

      // Total Progress
      users = users.map((element: any) => {
        let userProgress = this.userProgressCalculation.find(
          (p) =>
            p.userId === element.userId && p.courseId === element.group.course
        );
        if (userProgress) {
          return {
            ...element,
            totalProgress:
              String(Number(userProgress.courseProgress.finished) * 100) +
                "%" || "",
          };
        } else {
          return { ...element, totalProgress: "-" };
        }
      });

      //Progess until next session
      users = users.map((element: any) => {
        let res = "";

        let userProgress = this.userProgressCalculation.find(
          (p) =>
            p.userId === element.userId && p.courseId === element.group.course
        );
        if (userProgress) {
          if (element.nextCoachingSession) {
            if (element.nextCoachingSession?.enableUntilChapter === "") {
              return Math.round(userProgress.courseProgress.finished * 100);
            } else {
              let chapterProgress: number[] = [];
              userProgress.courseProgress.modules.forEach((module) => {
                for (let chapter of module.chapters) {
                  if (
                    this.getRelevantChapters(element)
                      .map((c) => c.id)
                      .includes(chapter.id)
                  ) {
                    chapterProgress.push(Math.round(chapter.finished * 100));
                  }
                }
              });
              let sum = chapterProgress.reduce((a, b) => a + b, 0);
              res = String(Math.round(sum / chapterProgress.length));
            }
          } else {
            // No coaching left
            res = "";
          }
        }
        return { ...element, partialProgress: res };
      });

      // App Info
      users = users.map((element) => {
        let match = this.profiles.filter((u) => u.userId === element.userId);
        if (match.length > 0) {
          return {
            ...element,
            appVersion: match[0].appVersion || "",
            platform: match[0].platform || "",
          };
        } else {
          return { ...element, appVersion: "-", platform: "-" };
        }
      });
      return users;
    },
  },
  watch: {
    selectedGroupIds(val) {
      localStorage.selectedGroupIds = JSON.stringify(val);
    },
    "currentBatch.id": function (newId, oldId) {
      if (newId != oldId) {
        this.setGroupFilter();
      }
    },
  },
  mounted() {
    if (localStorage.selectedGroupIds) {
      this.selectedGroupIds = JSON.parse(localStorage.selectedGroupIds);
    }
    this.setGroupFilter();
  },
  methods: {
    getRelevantChapters(user: any): Chapter[] {
      let chapters: Chapter[] = [];
      if (user.group) {
        let course = this.allCourses.find(
          (c: Course) => c.id.split("_")[0] === user.group.course
        );
        let start;
        let end;
        if (user.nextCoachingSession) {
          let index = user.group.coachings.findIndex((c) => {
            return c.id === user.nextCoachingSession.id;
          });
          if (index > 0) {
            start = user.group.coachings[index - 1].enableUntilChapter;
          }
          end = user.group.coachings[index].enableUntilChapter;
        }

        if (course?.modules) {
          let started = false;
          course.modules.forEach((module) => {
            if (!start) {
              // started is not defined because its the first coaching
              start = module.chapters[0].id;
              started = true;
            }
            for (let chapter of module.chapters) {
              if (started) {
                chapters.push(chapter);
              } else {
                // beginn
                if (chapter.id === start) {
                  started = true;
                }
              }
              if (end) {
                if (chapter.id === end) {
                  started = false;
                  break;
                }
              }
            }
          });
        }
      }
      return chapters;
    },
    getNextCoachingSession(group: CoachingGroup): CoachingSession | undefined {
      let today = new Date();
      if (group.coachings) {
        for (let i = 0; i < group.coachings.length; i++) {
          let c = group.coachings[i];
          if (!c.start) {
            continue;
          } else {
            let coachingDate = new Date(c.start);
            if (today < coachingDate) {
              return c;
            }
          }
        }
      }
    },
    addBatch(): void {
      if (
        this.addBatchData.name !== "" &&
        this.addBatchData.groups.length > 0
      ) {
        this.$store
          .dispatch("addDocument", {
            data: this.addBatchData,
            collection: batchCollection,
          })
          .then((res) => {
            this.addBatchData = { name: "", groups: [] };
            this.$router.push("/batch/" + res.id);
          });
      } else {
        alert("Missing Info!");
      }
      this.addBatchDialog = false;
    },
    saveCurrentBatch(): void {
      if (this.currentBatch) {
        this.$store
          .dispatch("updateDocument", {
            data: this.currentBatch,
            document: batchCollection.doc(this.currentBatch.id),
          })
          .then(() => (this.editBatchDialog = false));
      }
    },
    deleteCurrentBatch(): void {
      if (this.currentBatch) {
        if (confirm("Do you really want to delete this batch?")) {
          this.$store.dispatch("deleteDocument", {
            document: batchCollection.doc(this.currentBatch.id),
          });
        }
      }
    },
    setGroupFilter(): void {
      if (this.currentBatch) this.batchGroupFilter = this.currentBatch.groups;
    },
  },
});
